/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import Cookies from "js-cookie"
import { parse as parseQueryParams } from "query-string"
import "pure-react-carousel/dist/react-carousel.es.css"
import "components/layout/fonts.css"

import totalNavHeight from "utils/totalNavHeight"
import { getSegmentDeviceId } from "@mindbodygreen/analytics-tracking-helpers"
import App from "./src/app"

export const wrapPageElement = App

const trackPage = (location, prevLocation) => {
  // make Segment tracking call

  // pass Facebook (fbp, fbc) and Google (gclid) click IDs to Segment
  let facebookClickId = ""
  let googleClickId = ""
  if (location.search) {
    ;({ gclid: googleClickId, fbclid: facebookClickId } = parseQueryParams(location.search))
  }
  const identifyParams = {}
  const fbp = Cookies.get("_fbp") || ""
  const fbc = facebookClickId || Cookies.get("_fbc") || ""
  if (fbp) {
    identifyParams.facebook_pixel_id = fbp
  }
  if (fbc) {
    identifyParams.facebook_click_id = fbc
    if (facebookClickId) {
      identifyParams.facebook_click_date = new Date().toISOString()
    }
  }
  if (googleClickId) {
    identifyParams.google_click_id = googleClickId
    identifyParams.google_click_date = new Date().toISOString()
  }
  if (Object.keys(identifyParams).length) {
    try {
      window.analytics.identify(identifyParams)
    } catch (error) {
      console.error("Error identifying user:", error)
    }
  }

  // setTimeout to wait for document.title to be available for proper tracking
  window.setTimeout(() => {
    const page = `${process.env.GATSBY_LOCAL_SITE}${location.pathname}`
    const articleId = window.document.querySelector("meta[name='mbg.article_id']")?.content
    const properties = {
      url: page,
      path: location.pathname,
      title: window.document.title,
      ...(articleId && { object_type: "article", object_id: articleId }),
      ...(googleClickId && { google_click_id: googleClickId }),
      ...(facebookClickId && { facebook_click_id: facebookClickId }),
    }
    if (prevLocation) {
      properties.referrer = `${process.env.GATSBY_LOCAL_SITE}${prevLocation.pathname}`
    }

    const event = {
      page: {
        url: page,
      },
      device: { id: getSegmentDeviceId() },
    }
    if (location.pathname === "/") {
      window.analytics.page("Home Page", properties, event)
    } else {
      window.analytics.page(properties, event)
    }
  }, 50)
}

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // Set the scroll behavior to "auto" for all the internal links
  // avoiding the scroll to the top behavior
  try {
    if (
      prevLocation?.pathname !== location?.pathname &&
      prevLocation?.pathname !== `${location?.pathname}/`
    ) {
      window.document.getElementsByTagName("html")[0].style.scrollBehavior = "auto"
    }
  } catch (err) {
    // ignore
  }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // Next Line set the scroll behavior to "smooth" in case it was change
  // it on the onPreRouteUpdate function
  try {
    window.document.getElementsByTagName("html")[0].style.scrollBehavior = "smooth"
  } catch (err) {
    // ignore
  }

  trackPage(location, prevLocation)

  // support jumping to elements within a page, using a hash "#some-id"
  const { hash } = location
  try {
    const element = hash && document.querySelector(hash)
    if (element) {
      window.scrollTo({
        top: element.offsetTop - totalNavHeight(), // nav bar(s) taken into account
        left: 0,
      })
    }
  } catch (error) {
    console.log(error)
  }
}
export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  if (
    prevRouterProps &&
    prevRouterProps.location.pathname === routerProps.location.pathname &&
    routerProps.location.pathname.includes("gift-guide") &&
    prevRouterProps.location.search !== routerProps.location.search
  ) {
    const scrollPos = window.sessionStorage.getItem(`@@scroll|${prevRouterProps.location.key}`)
    if (scrollPos) {
      ;[, document.documentElement.scrollTop] = JSON.parse(scrollPos)
    }

    return false
  }
  window.scroll(0, 0)
  return false
}
