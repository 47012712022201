import styled from "styled-components"
import { Link } from "gatsby"

import { minWidth, maxWidth } from "utils"

import Icon from "icons"

export const Container = styled.footer`
  color: ${({ theme }) => theme.colors.text.primary};
`

export const Top = styled.div`
  width: 100%;
  padding: 3.6rem 2rem 6.4rem;
  display: flex;
  flex-direction: column;

  ${minWidth.mobile`
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  `}

  ${minWidth.hamburgerLarge`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 12.8rem;
    padding-top: 6.4rem;

  `}

  ${minWidth.rightRail`
    width: 100%;
  `}
`
export const GroupedSections = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3.6rem;

  ${minWidth.hamburgerLarge`
    flex-direction: row;
    gap: 4.8rem;
  `}
`

export const GroupedSection = styled.div`
  display: flex;
  gap: 1.2rem;

  > * {
    flex: 1;
  }

  ${minWidth.hamburgerLarge`
    gap: 4.8rem;
  `}
`

export const Line = styled.hr`
  height: 0.1rem;
  margin: 2rem 2rem 0;
  padding: 0;
  display: block;
  border: 0;
  border-top: 0.1rem solid rgba(34, 34, 34, 0.2);

  ${minWidth.hamburgerLarge`
    margin: 2rem 9.6rem 0;
  `}
`

export const NewsletterSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3.6rem 2rem;

  ${minWidth.hamburgerLarge`
    flex-direction: row;
    padding: 6.4rem 9.6rem;
    justify-content: space-between;
  `}
`

export const NewsletterContent = styled.div`
  flex-basis: 100%;

  ${minWidth.hamburgerLarge`
    flex-basis: 50%;
  `}
`

export const NewsletterContainer = styled.div`
  width: 35rem;

  ${minWidth.hamburgerLarge`
    width: 57.5rem;
  `}
`

export const NewsletterTitle = styled.h3`
  font-family: "Abril", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 2.4rem;
  font-style: italic;
  font-weight: 400;
  line-height: 2.025;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  margin-bottom: 2.8rem;

  ${minWidth.hamburgerLarge`
    font-size: 3.2rem;
  `}
`

export const NewsletterDescription = styled.p`
  width: 100%;
  margin: 0;
  font-family: Maax, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 400;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  line-height: 1.56;
`

export const NewsletterFormWrapper = styled.div`
  flex-basis: 50%;
  height: 100%;
`

export const MBGLogo = styled.div`
  font-family: Monoline, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 3.16rem;
  margin-bottom: 3.6rem;

  ::after {
    content: "Mindbodygreen";
  }
`

export const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  ${minWidth.desktopXL`
    margin: 0;
    width: 19.85rem;
  `}
`

export const LinkSectionTitle = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.62rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
`

export const StyledLink = styled(Link)`
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 2.808rem;
  width: fit-content;
`

export const StyledOuterLink = styled.a`
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 2.808rem;
`

export const Bottom = styled.div`
  padding: 6rem 2rem;
  background-color: #efeee8;

  ${minWidth.mobile`
    padding-left: 9.6rem;
    padding-right: 9.6rem;
  `}
`

export const UpperBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${minWidth.hamburgerLarge`
    flex-direction: row;
    align-items: flex-start;
    justify-content: ${(props) => (props.isHomePage ? "space-between" : "center")};
  `}
`

export const UpperBottomLeft = styled.div``

export const SocialLinks = styled.div`
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  ${minWidth.hamburgerLarge`
    justify-content: ${(props) => (props.isHomePage ? "flex-start" : "center")};
  `}
`

export const SocialIcon = styled(Icon)`
  max-width: 3rem;
  color: ${({ theme }) => theme.colors.text.primary};
`

export const Copyright = styled.div`
  font-size: 1.4rem;
  line-height: 2.184rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.text.primary};

  ${minWidth.hamburgerLarge`
    text-align: left;
  `}
`

export const Disclaimer = styled.p`
  display: ${({ isHomePage }) => (isHomePage ? "block" : "none")};
  max-width: 56.7rem;
  height: fit-content;
  margin: 2rem 0;
  padding: 1rem;
  border: 0.1rem solid rgba(34, 34, 34, 0.2);
  font-size: 1.4rem;
  line-height: 2.184rem;
  color: ${({ theme }) => theme.colors.text.primary};

  ${minWidth.hamburgerLarge`
    margin: 0;
  `}
`

export const UtilityLinks = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  a {
    margin: 1rem;
    font-size: 1.2rem;
    line-height: 1.872rem;
    color: ${({ theme }) => theme.colors.text.primary};
    border-bottom: 0.1rem solid ${({ theme }) => theme.colors.text.primary};
  }

  ${maxWidth.desktop`
    max-width: 144rem;
  `}
`

export const ButtonLink = styled.button`
  margin: 1rem;
  font-size: 1.2rem;
  line-height: 1.872rem;
  color: ${({ theme }) => theme.colors.text.primary};
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.text.primary};
  background: none;
  box-shadow: none;
`
